import React from 'react';
import { Container, Row, Col, NavLink, Button, Image } from 'react-bootstrap';
import Gimg from "../../Assets/images/home/google-img.webp";
import userImg from "../../Assets/images/home/slide-user-img.png";
import googleReview from "../../Assets/images/home/google-review-1.png";
import StarIcon from '@mui/icons-material/Star';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Link } from 'react-router-dom';
import axios from 'axios';
import { base_url, postHeader } from '../../Helpers/request';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ArrowLeft from '../../Assets/images/diamond-doves-img/arrow-left.png'
import ArrowRight from '../../Assets/images/diamond-doves-img/arrow-right.png'
import GoogleImg from '../../Assets/images/diamond-doves-img/google-img.png'
import ReviewUser from '../../Assets/images/diamond-doves-img/review-user.png'

const Customer = () => {
    const [customerData, setCustomerData] = React.useState([])
    React.useEffect(() => {
        GetCustomerRewies();
        console.log('customerdta', customerData)
    }, [])
    const GetCustomerRewies = () => {
        axios.get(`${base_url}/common/site_reviews`, {
            headers: postHeader
        }).then(res => {
            console.log(res)
            if (res.data.status == 1) {
                setCustomerData(res.data)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const customerReview = [
        {
            name: 'Alice Galtrey',
            reviewText: 'My partner proposed to me with a ring made here through Paresh, the process was long and very intricate with every step thought through and resulted in the most perfect ring of my dreams being made in a bespoke and hands on process and detailed consultation. Couldn’t be more happy and recommend more!!!',
            linkUrl: 'https://www.google.com/search?q=diamond+doves+hatton+garden&rlz=1C1VDKB_enIN1040IN1041&ei=mEV4ZIHKEOavseMP36WbsAQ&start=0&sa=N&ved=2ahUKEwiB9rqfw6H_AhXmV2wGHd_SBkY4ChDy0wN6BAgDEAQ&biw=1280&bih=601&dpr=1.5#lrd=0x48761b81c2b2bec5:0x81dba6d7b16fe8a8,1',
            reviewDate: "a day ago"
        },
        {
            name: 'Natalie Walker',
            reviewText: 'Thank you Diamond Doves, professional service, very helpful.  Would definitely go back to see Chintan & gang again! The only shop in Hatton Garden that was willing to help me find what I wanted in my budget! Thanks again & I hope to see you soon!',
            linkUrl: 'https://www.google.com/search?q=diamond+doves+hatton+garden&rlz=1C1VDKB_enIN1040IN1041&ei=mEV4ZIHKEOavseMP36WbsAQ&start=0&sa=N&ved=2ahUKEwiB9rqfw6H_AhXmV2wGHd_SBkY4ChDy0wN6BAgDEAQ&biw=1280&bih=601&dpr=1.5#lrd=0x48761b81c2b2bec5:0x81dba6d7b16fe8a8,1',
            reviewDate: "a day ago"
        },
        {
            name: 'Sean Whetstone',
            reviewText: 'Amazing experience with Chintan, we visited most of jewellers in Hatton Garden before deciding to trust Diamond Doves for our engagement ring. Great advice, super designs and competitive price led to a fantastic ring shopping experience. Would recommend and will be back for our wedding bands.',
            linkUrl: 'https://www.google.com/search?q=diamond+doves+hatton+garden&rlz=1C1VDKB_enIN1040IN1041&ei=mEV4ZIHKEOavseMP36WbsAQ&start=0&sa=N&ved=2ahUKEwiB9rqfw6H_AhXmV2wGHd_SBkY4ChDy0wN6BAgDEAQ&biw=1280&bih=601&dpr=1.5#lrd=0x48761b81c2b2bec5:0x81dba6d7b16fe8a8,1',
            reviewDate: "2 day ago"
        },
        {
            name: 'charlie law',
            reviewText: 'Great customer service. They really looked after me and supplied the perfect engagement ring. Also resized for free. Will definitely be back the the wedding rings. Thanks guys',
            linkUrl: 'https://www.google.com/search?q=diamond+doves+hatton+garden&rlz=1C1VDKB_enIN1040IN1041&ei=mEV4ZIHKEOavseMP36WbsAQ&start=0&sa=N&ved=2ahUKEwiB9rqfw6H_AhXmV2wGHd_SBkY4ChDy0wN6BAgDEAQ&biw=1280&bih=601&dpr=1.5#lrd=0x48761b81c2b2bec5:0x81dba6d7b16fe8a8,1',
            reviewDate: "6 day ago"
        },
        {
            name: 'Olivér Szász',
            reviewText:"I have had the pleasure to do business with Paresh and the team this Friday regarding an engagement ring. What a fantastic experience!' 'These guys are one of the best, if not the best in all of Hatton Gardens when it comes to diamonds and engagement rings. They are very friendly, knowledgable and extremely flexible in order to meet the needs of whatever you are looking for. Paresh was very transparent throughout the process and they had one of the widest selection of diamonds and rings available that I have ever seen. I really felt like no matter what question or ask I had, Paresh and the team took care of me throughout the process.Couldn't recommend them more! - Oliver",
            linkUrl: 'https://www.google.com/search?q=diamond+doves+hatton+garden&rlz=1C1VDKB_enIN1040IN1041&ei=mEV4ZIHKEOavseMP36WbsAQ&start=0&sa=N&ved=2ahUKEwiB9rqfw6H_AhXmV2wGHd_SBkY4ChDy0wN6BAgDEAQ&biw=1280&bih=601&dpr=1.5#lrd=0x48761b81c2b2bec5:0x81dba6d7b16fe8a8,1',
            reviewDate: "a week ago"
        },
        {
            name: 'Saba',
            reviewText: 'The best jewellers in Hatton Garden! They have such a great selection of rings and even custom made options. Paresh was especially great in helping me find the perfect ring, which is absolutely stunning. Excellent customer service too!',
            linkUrl: 'https://www.google.com/search?q=diamond+doves+hatton+garden&rlz=1C1VDKB_enIN1040IN1041&ei=mEV4ZIHKEOavseMP36WbsAQ&start=0&sa=N&ved=2ahUKEwiB9rqfw6H_AhXmV2wGHd_SBkY4ChDy0wN6BAgDEAQ&biw=1280&bih=601&dpr=1.5#lrd=0x48761b81c2b2bec5:0x81dba6d7b16fe8a8,1',
            reviewDate: "a week ago"
        },
        // {
        //     name: 'Elise Vosoughi',
        //     reviewText: 'It’s taken me about a month to write this review, because I’ve been walking around trying to capture the perfect photo to go with it, but nothing does this ring justice! It’s a beautiful piece,',
        //     linkUrl: 'https://www.google.com/search?q=diamond+doves+hatton+garden&rlz=1C1VDKB_enIN1040IN1041&ei=mEV4ZIHKEOavseMP36WbsAQ&start=0&sa=N&ved=2ahUKEwiB9rqfw6H_AhXmV2wGHd_SBkY4ChDy0wN6BAgDEAQ&biw=1280&bih=601&dpr=1.5#lrd=0x48761b81c2b2bec5:0x81dba6d7b16fe8a8,1',
        //     reviewDate: "2 months ago"
        // },
        // {
        //     name: 'Sona S',
        //     reviewText: 'Had the most wonderful experience here at Diamond Doves with Chintan, Paresh and the team. From the moment we walked in they were so helpful and knowledgeable,',
        //     linkUrl: 'https://www.google.com/search?q=diamond+doves+hatton+garden&rlz=1C1VDKB_enIN1040IN1041&ei=mEV4ZIHKEOavseMP36WbsAQ&start=0&sa=N&ved=2ahUKEwiB9rqfw6H_AhXmV2wGHd_SBkY4ChDy0wN6BAgDEAQ&biw=1280&bih=601&dpr=1.5#lrd=0x48761b81c2b2bec5:0x81dba6d7b16fe8a8,1',
        //     reviewDate: "3 weeks ago"
        // },
        // {
        //     name: 'Marium Ahmed',
        //     reviewText: 'Diamond Doves was recommended by a close friend and they did not disappoint. From the initial appointment they catered to my requests',
        //     linkUrl: 'https://www.google.com/search?q=diamond+doves+hatton+garden&rlz=1C1VDKB_enIN1040IN1041&ei=mEV4ZIHKEOavseMP36WbsAQ&start=0&sa=N&ved=2ahUKEwiB9rqfw6H_AhXmV2wGHd_SBkY4ChDy0wN6BAgDEAQ&biw=1280&bih=601&dpr=1.5#lrd=0x48761b81c2b2bec5:0x81dba6d7b16fe8a8,1',
        //     reviewDate: "3 weeks ago"
        // },
        // {
        //     name: 'Leon Box',
        //     reviewText: 'A Seamless, Brilliant Experience at Diamond Doves I am pleased to share my genuinely delightful experience with Diamond Doves. My wife and I visited the store to upgrade a wedding ring for our 20th anniversary, and we were overwhelmed by their sparkling collection. The gems are not just jewellery here; they are an artistic symphony of grace and elegance, promising an unwavering devotion akin to the institution of marriage.',
        //     linkUrl: 'https://www.google.com/search?q=diamond+doves+hatton+garden&rlz=1C1VDKB_enIN1040IN1041&ei=mEV4ZIHKEOavseMP36WbsAQ&start=0&sa=N&ved=2ahUKEwiB9rqfw6H_AhXmV2wGHd_SBkY4ChDy0wN6BAgDEAQ&biw=1280&bih=601&dpr=1.5#lrd=0x48761b81c2b2bec5:0x81dba6d7b16fe8a8,1',
        //     reviewDate: "3 weeks ago"
        // },
        // {
        //     name: "Jack Harding",
        //     reviewText: "Both my partner and I purchased our weddings rings from Diamond Doves after I wanted to return following buying an engagement ring from the shop last year",
        //     linkUrl: 'https://www.google.com/search?q=diamond+doves+hatton+garden&rlz=1C1VDKB_enIN1040IN1041&ei=mEV4ZIHKEOavseMP36WbsAQ&start=0&sa=N&ved=2ahUKEwiB9rqfw6H_AhXmV2wGHd_SBkY4ChDy0wN6BAgDEAQ&biw=1280&bih=601&dpr=1.5#lrd=0x48761b81c2b2bec5:0x81dba6d7b16fe8a8,1',
        //     reviewDate: "4 weeks ago"
        // },
        // {
        //     name: "Jen V",
        //     reviewText: "Chintan and his team have provided an excellent service from beginning to end. He isn't a pushy sales person and works with you to help you get what you want with his expert knowledge and recommendations",
        //     linkUrl: 'https://www.google.com/search?q=diamond+doves+hatton+garden&rlz=1C1VDKB_enIN1040IN1041&ei=mEV4ZIHKEOavseMP36WbsAQ&start=0&sa=N&ved=2ahUKEwiB9rqfw6H_AhXmV2wGHd_SBkY4ChDy0wN6BAgDEAQ&biw=1280&bih=601&dpr=1.5#lrd=0x48761b81c2b2bec5:0x81dba6d7b16fe8a8,1',
        //     reviewDate: "3 months ago"
        // },
        // {
        //     name: "M Kazemi",
        //     reviewText: "If you're ever going to go for jewelry in Hatton Gardens, there is only one place to go and that is Diamond Doves! ",
        //     linkUrl: 'https://www.google.com/search?q=diamond+doves+hatton+garden&rlz=1C1VDKB_enIN1040IN1041&ei=mEV4ZIHKEOavseMP36WbsAQ&start=0&sa=N&ved=2ahUKEwiB9rqfw6H_AhXmV2wGHd_SBkY4ChDy0wN6BAgDEAQ&biw=1280&bih=601&dpr=1.5#lrd=0x48761b81c2b2bec5:0x81dba6d7b16fe8a8,1',
        //     reviewDate: "3 months ago"
        // },
        // {
        //     name: "Alex Cherrill",
        //     reviewText: 'I would 100% recommend Diamond Doves! I did some research online for engagement rings to see the sort of style I wanted and saw some on the website that I liked.',
        //     linkUrl: 'https://www.google.com/search?q=diamond+doves+hatton+garden&rlz=1C1VDKB_enIN1040IN1041&ei=mEV4ZIHKEOavseMP36WbsAQ&start=0&sa=N&ved=2ahUKEwiB9rqfw6H_AhXmV2wGHd_SBkY4ChDy0wN6BAgDEAQ&biw=1280&bih=601&dpr=1.5#lrd=0x48761b81c2b2bec5:0x81dba6d7b16fe8a8,1',
        //     reviewDate: "a month ago"
        // },
        // {
        //     name: "Rajen Shah",
        //     reviewText: 'Amazing service by Chintan and team. High quality bespoke service, making the rings to the design we wanted and perfect execution. We were very impressed by the attention and friendliness of the team and would highly recommend to anyone',
        //     linkUrl: 'https://www.google.com/search?q=diamond+doves+hatton+garden&rlz=1C1VDKB_enIN1040IN1041&ei=mEV4ZIHKEOavseMP36WbsAQ&start=0&sa=N&ved=2ahUKEwiB9rqfw6H_AhXmV2wGHd_SBkY4ChDy0wN6BAgDEAQ&biw=1280&bih=601&dpr=1.5#lrd=0x48761b81c2b2bec5:0x81dba6d7b16fe8a8,1',
        //     reviewDate: "3 weeks ago"
        // }
    ]


    var settings2 = {
        dots: false,
        infinite: true,
        speed: 500,
        accessibility: true,
        arrows: true,
        centerMode: true,
        prevArrow: <img src={ArrowLeft} alt="left Arrow" />,
        nextArrow: <img src={ArrowRight} alt="Right Arrow" />,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },

            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1,
                    centerMode: false
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: false
                }
            }
        ]
    };

    return (
        <>
            <section className="rcs_customer_say_section">
                <Container className="ddov_cutome_container">
                    <Row>
                        <Col lg="12" col="12">
                            <div className="rcs_customer_say_title" data-aos="fade-up" data-aos-duration="1000">
                                <h6 className='ddov_google_review'>290+<sapn><img src={GoogleImg} alt="Google Image" onClick={() => window.open('https://www.google.com/search?q=diamond+doves+london&sca_esv=569971392&rlz=1C1CHZN_enIN1077IN1077&ei=8YgaZYagAvyL4-EP6uS1sA8&ved=0ahUKEwjG3L-ggteBAxX8xTgGHWpyDfYQ4dUDCBA&uact=5&oq=diamond+doves+london&gs_lp=Egxnd3Mtd2l6LXNlcnAiFGRpYW1vbmQgZG92ZXMgbG9uZG9uMgsQLhiABBjHARivATIIEAAYigUYhgMyCBAAGIoFGIYDMggQABiKBRiGAzIIEAAYigUYhgMyCBAAGIoFGIYDMhoQLhiABBjHARivARiXBRjcBBjeBBjgBNgBA0iWF1DUB1jeFHABeAGQAQCYAbYBoAHcCKoBAzAuN7gBA8gBAPgBAcICChAAGEcY1gQYsAPCAgoQABiKBRiwAxhDwgIOEAAY5AIY1gQYsAPYAQHCAhAQLhiKBRjIAxiwAxhD2AECwgIHEAAYigUYQ8ICDRAuGK8BGMcBGIoFGEPCAgUQABiABMICBhAAGBYYHsICCBAAGBYYHhgP4gMEGAAgQYgGAZAGE7oGBggBEAEYCboGBggCEAEYCLoGBggDEAEYFA&sclient=gws-wiz-serp#lrd=0x48761b81c2b2bec5:0x81dba6d7b16fe8a8,1,,,,', "_blank")} />Reviews</sapn></h6>
                                <h2> Our Happy Customers </h2>
                                <p>Here’s the Words of Praise of our Customers</p>
                            </div>
                        </Col>
                        <Col sm={12} md={12} lg={12} data-aos="fade-up" data-aos-duration="1400">
                            <div className="rcs_customer_review_slider">
                                <Slider className="rcs_customer_say_inner" {...settings2}>
                                    {customerReview.map(val =>
                                        <div className='ddov_review_out_box'>
                                            <div className="rcs_customer_review_slide_item">
                                                <div className='white_bg'>
                                                    <div className="rcs_customer_slide_text_content">
                                                        <Rating name="half-rating-read" defaultValue={5} size="medium" readOnly style={{ color: '#F4C01E' }} />
                                                        <p>
                                                            {val.reviewText}
                                                        </p>
                                                        <Link to="#" onClick={() => window.open('https://www.google.com/search?q=diamond+doves+london&sca_esv=569971392&rlz=1C1CHZN_enIN1077IN1077&ei=8YgaZYagAvyL4-EP6uS1sA8&ved=0ahUKEwjG3L-ggteBAxX8xTgGHWpyDfYQ4dUDCBA&uact=5&oq=diamond+doves+london&gs_lp=Egxnd3Mtd2l6LXNlcnAiFGRpYW1vbmQgZG92ZXMgbG9uZG9uMgsQLhiABBjHARivATIIEAAYigUYhgMyCBAAGIoFGIYDMggQABiKBRiGAzIIEAAYigUYhgMyCBAAGIoFGIYDMhoQLhiABBjHARivARiXBRjcBBjeBBjgBNgBA0iWF1DUB1jeFHABeAGQAQCYAbYBoAHcCKoBAzAuN7gBA8gBAPgBAcICChAAGEcY1gQYsAPCAgoQABiKBRiwAxhDwgIOEAAY5AIY1gQYsAPYAQHCAhAQLhiKBRjIAxiwAxhD2AECwgIHEAAYigUYQ8ICDRAuGK8BGMcBGIoFGEPCAgUQABiABMICBhAAGBYYHsICCBAAGBYYHhgP4gMEGAAgQYgGAZAGE7oGBggBEAEYCboGBggCEAEYCLoGBggDEAEYFA&sclient=gws-wiz-serp#lrd=0x48761b81c2b2bec5:0x81dba6d7b16fe8a8,1,,,,', "_blank")}>  Read More </Link>
                                                        <LazyLoadImage src={googleReview} onClick={() => window.open('https://www.google.com/search?q=diamond+doves+london&sca_esv=569971392&rlz=1C1CHZN_enIN1077IN1077&ei=8YgaZYagAvyL4-EP6uS1sA8&ved=0ahUKEwjG3L-ggteBAxX8xTgGHWpyDfYQ4dUDCBA&uact=5&oq=diamond+doves+london&gs_lp=Egxnd3Mtd2l6LXNlcnAiFGRpYW1vbmQgZG92ZXMgbG9uZG9uMgsQLhiABBjHARivATIIEAAYigUYhgMyCBAAGIoFGIYDMggQABiKBRiGAzIIEAAYigUYhgMyCBAAGIoFGIYDMhoQLhiABBjHARivARiXBRjcBBjeBBjgBNgBA0iWF1DUB1jeFHABeAGQAQCYAbYBoAHcCKoBAzAuN7gBA8gBAPgBAcICChAAGEcY1gQYsAPCAgoQABiKBRiwAxhDwgIOEAAY5AIY1gQYsAPYAQHCAhAQLhiKBRjIAxiwAxhD2AECwgIHEAAYigUYQ8ICDRAuGK8BGMcBGIoFGEPCAgUQABiABMICBhAAGBYYHsICCBAAGBYYHhgP4gMEGAAgQYgGAZAGE7oGBggBEAEYCboGBggCEAEYCLoGBggDEAEYFA&sclient=gws-wiz-serp#lrd=0x48761b81c2b2bec5:0x81dba6d7b16fe8a8,1,,,,', "_blank")} />
                                                    </div>
                                                </div>
                                                <div className='ddove_review_user'>
                                                    <div className='d-flex'>
                                                        {/* <Image src={ReviewUser} alt="customer image" /> */}
                                                        <div>
                                                            <h6>{val.name}</h6>
                                                            {/* <p>{val.reviewDate}</p> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Slider>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default Customer;
