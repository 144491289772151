import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import DiamondsImg from '../../Assets/images/diamond-doves-img/diamonds-img.png'
import WeddingBandImg from '../../Assets/images/diamond-doves-img/wedding-band.png'
// import GemstoneImg from '../../Assets/images/diamond-doves-img/gemstones-img.png'
import { NavLink } from 'react-router-dom/cjs/react-router-dom'
import { BsArrowRight } from 'react-icons/bs'

const DiamondsGemstones = () => {
    return (
        <>

            <div className='ddov_diamond_gemstone_section'>
                <Container className='ddov_cutome_container '>
                    <Row>
                        <Col sm={12} md={6} lg={6}>
                            <div className='ddov_dimond_box_with_img ddov_text_dia_gem_same text-center'>
                                <Image src={DiamondsImg} alt="Diamonds Image" className="img-fluid" />
                                <h4>Diamonds</h4>
                                <p>Find the perfect gem in our sparkling selection of certified diamonds,
                                    and refine your search by carat, colour, clarity and cut.</p>
                                <NavLink to="/diamonds">Shop Diamonds<span><BsArrowRight /></span></NavLink>
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <div className='ddov_gemstones_box_with_img ddov_text_dia_gem_same text-center'>
                                <Image src={WeddingBandImg} alt="Weedding Band Image" className="img-fluid" />
                                <h4>Wedding Bands</h4>
                                <p>A circle of eternal love, wrapped around your finger, in this band, we find forever, in each other, we find everything.</p>
                                <NavLink to="/jewellery/wedding-rings">Wedding Bands<span><BsArrowRight /></span></NavLink>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default DiamondsGemstones