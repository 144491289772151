import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import AboutUsBanner from '../../Assets/images/diamond-doves-img/aboutUs-img.png'
import AboutUsBannerMobile from '../../Assets/images/diamond-doves-img/aboutUsMobile-img.png'
import { useHistory } from 'react-router-dom'
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';

const AboutUs = () => {
    const history = useHistory();
    return (
        <>
            <div className='ddov_about_us_section_home'>
                <Container className='ddov_cutome_container'>
                    <Row className='w-100 m-auto'>
                        <Col sm={12} md={12} lg={12} className='p-0'>
                            <div className='ddov_about_img position-relative text-center'>
                                <Image src={AboutUsBanner} alt="About Us banner" className='img-fluid desktop_img' />
                                <Image src={AboutUsBannerMobile} alt="About Us banner" className='img-fluid mobile_img' />
                                <div className='ddov_about_text_box text-left'>
                                    <h2>About Us</h2>
                                    <p> Our strong belief in personalization & quality has led us over the last 15 years to grow thanks to our patrons. The team at Diamond Doves consists of designers, master craftsmen & highly experienced jewellers who ensure that your jewellery is of the best quality & with a high standard of durability... </p>
                                    <button onClick={() => history.push("/our-story")}>Know More</button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default AboutUs