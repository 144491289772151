import React from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import Showrrom from '../../Assets/images/diamond-doves-img/showroom-img.png'
import ShowrromMobile from '../../Assets/images/diamond-doves-img/showroom-img-mobile.png'
import ShowrromTablet from '../../Assets/images/diamond-doves-img/showroom-img-tablet.png'
import { useHistory } from 'react-router-dom'


const Showroom = () => {
    const history = useHistory();
    return (
        <div className='ddov_shoroom_section'>
            <Container className="ddov_cutome_container">
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <div className='ddov_showrrom_text_box'>
                            <Image src={Showrrom} alt="Showroom Image" className='img-fluid s_only_desktop' />
                            <Image src={ShowrromMobile} alt="Showroom Image" className='img-fluid s_only_mobile' />
                            <Image src={ShowrromTablet} alt="Showroom Image" className='img-fluid s_only_tablet' />
                            <div className='ddov_showrrom_textbtn_box'>
                                <h2>Visit Our Showroom</h2>
                                <p>Come and experience the shimmering world of finest jewellery at our exquisite showroom.
                                    We invite you to visit our showroom and embark on a journey of timeless beauty and luxury.
                                    Let us be your trusted destination for all your jewellery desires, where the dreams come
                                    true. Discover our showroom and allow us to assist you in finding that
                                    perfect piece for you.</p>
                                <button className='ddove_btn_book_an_appointment' onClick={() => history.push("/make-an-appointment")}>Book an Appointment</button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Showroom