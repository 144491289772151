import React from 'react'
import EngRing from '../../Assets/images/diamond-doves-img/eng-ring.png'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

const EngagementRing = () => {
    const history = useHistory();
    return (
        <>
            <div className='ddov_eng_ring_section'>
                <Container className='ddov_cutome_container'>
                    <Row>
                        <Col sm={12} md={6} lg={6} className='d-flex justify-content-center'>
                            <div className='eng_ring_box'>
                                <Image src={EngRing} alt="Enagemet Ring" />
                            </div>
                        </Col>
                        <Col sm={12} md={6} lg={6} className="d-flex justify-content-center align-items-center">
                            <div className='ddov_eng_ring_text_box'>
                                <h5>Create Your Own</h5>
                                <h3>Engagement Ring</h3>
                                <p>Build the Ring of Your Dreams with Our Interactive Feature.</p>
                                <div className='d-flex'>

                                    <Button className='ddov_btn_gray' onClick={() => history.push("/ringsettings/diamonds")}>Start with a Setting</Button>
                                    <Button className='ddov_btn_outline' onClick={() => history.push("/diamonds")}>Start with a Diamond</Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

        </>
    )
}

export default EngagementRing