import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'

const BespokeJewellery = () => {
  const history = useHistory();
  return (
    <>
      <div className='ddov_baspoke_jewelery_section'>
        <Container>
          <Row>
            <Col sm={12} md={12} lg={12}>
              <div className='ddov_bj_text text-center'>
                <h3>Bespoke Jewellery</h3>
                <p>Design by you and crafted by us. Discover the World
                  of Bespoke Jewellery.</p>
                <button className='ddov_btne_bs_jewel' onClick={() => history.push("/custom-design")}>Discover Bespoke</button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default BespokeJewellery