import React, { useEffect } from 'react'
import { Col, Container, Row, Image } from 'react-bootstrap'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { Link } from 'react-router-dom';
import RingImg from '../../Assets/images/diamond-doves-img/best-seller-img/ring-img.png'
import PendantImg from '../../Assets/images/diamond-doves-img/best-seller-img/pandent-img.png'
import EarningImg from '../../Assets/images/diamond-doves-img/best-seller-img/earing-img.png'
import Ring1Img from '../../Assets/images/diamond-doves-img/best-seller-img/ring-img1.png'
import BraslateImg from '../../Assets/images/diamond-doves-img/best-seller-img/breslate-img.png'
import Slider from "react-slick";
import ArrowNext from '../../Assets/images/diamond-doves-img/best-seller-img/next-img.png'
import ArrowPerv from '../../Assets/images/diamond-doves-img/best-seller-img/prev-img.png'
import axios from 'axios';
import { base_url } from '../../Helpers/request';
import { useState } from 'react';

const BestSeller = () => {
    const [data, setData] = useState([]);

    const getData = async () => {
        const options = {
            method: "GET",
            headers: {
                Accept: "application/json"
            },
        };

        await axios.get(base_url + "/Common/bestseller", options).then((res) => {

            if (res && res.status === 200) {
                setData(res.data.data);
            }
        });
    };

    useEffect(() => {
        getData();
    }, [])



    const BestSellerImg = [
        {
            img: RingImg,
            tittle: 'Twisted Shank Engagement Ring',
            price: '£1,597.20',

        },
        {
            img: Ring1Img,
            tittle: 'Engagement Rings Trellins',
            price: '£1,242.60',
        },
        {
            img: PendantImg,
            tittle: 'Solitaire Diamond Pendant',
            price: '£872.07',
        },
        {
            img: EarningImg,
            tittle: 'Fashion Diamond Earrings',
            price: '£812.05',
        },
        {
            img: BraslateImg,
            tittle: 'Prong Diamond Bracelet',
            price: '£1,242.60',
        },
        {
            img: EarningImg,
            tittle: 'Fashion Diamond Earrings',
            price: '£600.05',
        },

    ]



    const settingsBestSeller = {
        dots: false,
        infinite: true,
        autoplay: true,
        center: true,
        prevArrow: <Image src={ArrowPerv} alt="Prev Image" className='img-fluid' />,
        nextArrow: <Image src={ArrowNext} alt="Prev Image" className='img-fluid' />,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <>
            <div className='ddov_best_seller_sewction'>
                <Container fluid>
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            <div className='rcs_ringstyle_title text-center'>
                                <h2>Diamond Doves Best Sellers</h2>
                                <p>Join the Trend: Experience Our Best Selling Products.</p>
                            </div>
                        </Col>
                    </Row>
                    <Row className='w-100 m-auto'>
                        <Col sm={12} md={12} lg={12}>
                            <Slider {...settingsBestSeller} className='ddov_best_seller_slider'>
                                {data.length > 0 && data?.map((item, ind) => {
                                    return <div key={ind}>
                                        <div className=''>
                                            <Link to={`/productdetail/${item?.slug}`} class='item'>
                                                <Image src={item?.product_image} alt={item?.name} className='img-fluid ddov_best_slidew_img' />
                                            </Link>
                                            <div className='text-center ddov_best_seller_text'>
                                                <h3>{item.name}</h3>
                                                <p>{item.sale_price}</p>
                                            </div>
                                        </div>

                                    </div>
                                })
                                }
                            </Slider>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default BestSeller