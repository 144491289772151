import React, { useEffect, useLayoutEffect, useState } from "react";
import {
  Col,
  Container,
  Image,
  Nav,
  Row,
  Form,
  Dropdown,
  Modal,
  Accordion,
} from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import "../../Assets/css/MegaMenu.css";
import "../../Assets/css/dimond_doves_home.css"
import logoImage from "../../Assets/images/diamond-doves-img/dimond-doves-logo.png";
import logoImageMobile from "../../Assets/images/diamond-doves-img/dimond-doves-logo-mobile-view.png";
import SearchIcon from "@mui/icons-material/Search";
import Login from "../../Components/Login";
import { useHistory } from "react-router-dom";
import { Drawer } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";

import menuImages from "../../Assets/images/menu.png";
import { Email_details, base_url, client_name, contact_number, isLogin } from "../../Helpers/request";
import { isMobile, isMobileOnly, isTablet } from "react-device-detect";
import "../../../node_modules/react-slidedown/lib/slidedown.css";
import { MdOutlineShoppingBag } from "react-icons/md";
import { BiHeart } from "react-icons/bi";
import { BiSearch } from "react-icons/bi";
import { AiOutlineClose, AiOutlineConsoleSql } from "react-icons/ai";
import { BsTelephone } from "react-icons/bs";
import { BsEnvelope } from "react-icons/bs";
import Heart1 from "../../Assets/images/heart.png";
import Heart2 from "../../Assets/images/heart.png";
import Cart1 from "../../Assets/images/bag.png";
import Cart2 from "../../Assets/images/bag.png";
import Magnify0 from "../../Assets/images/search.png";
import Magnify1 from "../../Assets/images/search.png";
import CalanederIcon from "../../Assets/images/calendar.png";
import { useSelector } from "react-redux";
import { BsFillChatFill, BsFillTelephoneFill } from "react-icons/bs";
import { IoMdMail, IoMdContact } from "react-icons/io";
import { img_url } from "../../Helpers/request";
import { InputAdornment, TextField } from "@mui/material";
import axios from "axios";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

const MegaMenu = () => {
  const contact_number_link = contact_number.link;
  const Email_details_link = Email_details.link;
  const menudata = useSelector((state) => state.persistedReducer.home.menu);
  const [submenu, setSubmenu] = useState([]);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const [
    isScrollValueMoreThanHeaderHeight,
    setIsScrollValueMoreThanHeaderHeight,
  ] = useState(false);
  const [dropdown, setDropdown] = useState({
    img: localStorage.getItem("bw-currency_img"),
    icon: localStorage.getItem("bw-currencyicon"),
    code: localStorage.getItem("bw-currency"),
  });
  const [currencydata, setCurrencydata] = useState([]);
  const [topHeader, setTopHeader] = useState(true);
  const [left, setLeft] = useState(false);
  const [hover, setHover] = useState(0);
  const [hoverindex, setHoverindex] = useState(false);
  const [search_text, setSearch_text] = useState("");
  const [search_show, setSearch_show] = useState(false);
  const [wishover, setWishover] = useState(false);
  const [carthover, setCarthover] = useState(false);
  const [searchhover, setSearchhover] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  };


  const cartlength = useSelector(
    (state) => state.persistedReducer.cart.cartlength
  );
  const wishlistlength = useSelector(
    (state) => state.persistedReducer.wish.whishlistlength
  );
  const toggleDrawer = (open) => {
    setLeft(open);
  };
  const classes = useStyles();
  useEffect(() => {
    const handleScroll = () => {
      setIsScrollValueMoreThanHeaderHeight(window.scrollY > 15);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        includedLanguages: "en,es,fr,de,iw,ja,zh-CN,sv,tr,ar,hi",
        gaTrack: true,
      },
      "google_translate_element"
    );
  };

  useEffect(() => {
    //---------------------------- currency api
    axios.get(base_url + '/common/currency')
      .then(res => {
        if (res.data.status == 1) {
          setCurrencydata(res.data.data);
          localStorage.getItem("bw-currency") ? localStorage.getItem("bw-currency") : localStorage.setItem("bw-currency", res.data.data[0]?.currency_code);
          localStorage.getItem("bw-currency_img") ? localStorage.getItem("bw-currency_img") : localStorage.setItem("bw-currency_img", res.data.data[0]?.country_flag);
          localStorage.getItem("bw-currencyicon") ? localStorage.getItem("bw-currencyicon") : localStorage.setItem("bw-currencyicon", res.data.data[0]?.currency_icon);

        } else {
          console.log(res.data.message)
        }
      })
      .catch(err => {
        console.log(err)
      })
  }, []);
  const currencyHandle = async (code, img, icon) => {
    setDropdown({ img: img, icon: icon, code: code });
    localStorage.removeItem("bw-fancycolordata");
    localStorage.removeItem("bw-gemstonedata");
    localStorage.removeItem("bw-diamonddata");
    localStorage.removeItem("bw-settingdata");
    localStorage.setItem("bw-currency", code);
    localStorage.setItem("bw-currency_img", img);
    localStorage.setItem("bw-currencyicon", icon);
    sessionStorage.removeItem("bw-listfilter");
    sessionStorage.removeItem("bw_f_filter");
    sessionStorage.removeItem("bw_s_filter");
    sessionStorage.removeItem("bw_d_filter");
    sessionStorage.removeItem("bw_g_filter");
    sessionStorage.removeItem("bw-list-category");
    if (window.location.pathname == "/complete-diamond-ring") {
      history.push("/diamonds");
    } else if (window.location.pathname == "/complete-gemstone-ring") {
      history.push("/gemstones");
    } else if (window.location.pathname == "/complete-fancycolor-ring") {
      history.push("/fancy-color-diamond");
    }
    window.location.reload();
  };
  return (
    <>
      <div className="rcs_header_seaction" id="rcs_header_seaction">
        {/* <div className="rcs_header_seaction" id="rcs_header_seaction"> */}
        {/* Top Header Section */}
        {topHeader ? (
          <div className="rcs_top_header">
            <Container className="rcs_custom_home_container">
              <div className="rcs_top_head_content">
                <Row className="w-100 m-auto">
                  {/* <Col xs={12} md={9} lg={4} className="p-0">
                    <div className="rcs_top_head_content_right">
                      <ul>
                        <li
                          onClick={(e) => {
                            window.location.href = `${contact_number_link}`;
                          }}
                        >
                          <span>
                            <BsTelephone />
                          </span>{" "}
                         {contact_number.text}{" "}
                        </li>
                        <li
                          onClick={(e) => {
                            window.location.href = `${Email_details_link}`;
                          }}
                        >
                          <span>
                            <BsEnvelope />
                          </span>{" "}
                          {Email_details.text}{" "}
                        </li>
                      </ul>
                    </div>
                  </Col> */}
                  {isMobile ? (
                    <Col xs={12} md={12} lg={12} className="p-0">
                      <p>Free Shipping All Over The World</p>
                    </Col>
                  ) : (
                    <Col xs={12} md={12} lg={12} className="p-0">
                      <p>Free Shipping All Over The World</p>
                    </Col>
                  )}
                  {/* <Col xs={12} md={3} lg={4} className="p-0">
                    <div
                      id="google_translate_element"
                      className="float-right"
                    ></div>
                  </Col> */}
                </Row>
              </div>
            </Container>
          </div>
        ) : (
          ""
        )}
        {/* Top Header Section */}
        <div
          className={
            isScrollValueMoreThanHeaderHeight
              ? "rcs_logo_head rcs_sticky-top sticky-top"
              : "rcs_logo_head"
          }
        >
          {/* Header Section */}
          <div className="rcs_header shadow-none">
            <Container fluid className="p-0 ddov_cutome_container">
              <Row className="w-100 m-auto">
                <Col sm={12} md={12} lg={12} xs={12} className="rcs_large_Tab1">
                  {/* Menu Section */}
                  <div className="mg_main_d ddov_hed_padding justify-content-between">
                    <div className="ddov_left_side_hed">
                      <div className="ddov_book_an_appontment">
                        <NavLink to="/make-an-appointment"><Image src={CalanederIcon} alt="calander Icon" /> <span> Book an Appointment</span></NavLink>
                      </div>
                    </div>
                    <div className="mg_menu_logo_main_div">
                      <Nav className="m-auto rcs_mobile_header_menu">
                        <Image
                          onClick={() => toggleDrawer(true)}
                          src={menuImages}
                          alt="menu icon"
                          width="30px"
                        ></Image>
                      </Nav>
                      <div className="rcs_logo_section">
                        {/* <NavLink to="/"> */}
                        <Image
                          onClick={() => history.push("/")}
                          src={logoImageMobile}
                          alt={client_name.c_name}
                          className="ddov_logo_display_none_in_tab"
                        ></Image>
                        <Image
                          onClick={() => history.push("/")}
                          src={logoImage}
                          alt={client_name.c_name}
                          className="ddov_logo_display_none_in_mobile"
                        ></Image>
                        {/* </NavLink> */}
                      </div>
                      <div className="rcs_right_side rcs_right_side_mobile1 d-lg-none">
                        <ul>
                          <li className="rcs_currency">
                            <Dropdown className="d-inline rcs_dropdown_lang">
                              <Dropdown.Toggle id="dropdown-autoclose-true">
                                <Image
                                  src={dropdown?.img}
                                  alt={dropdown?.code}
                                  style={{ width: "25px" }}
                                ></Image>{" "}
                                <span>{dropdown?.code}</span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {currencydata.map((val) => (
                                  <Dropdown.Item
                                    onClick={() => {
                                      currencyHandle(
                                        val.currency_code,
                                        val.country_flag,
                                        val.currency_icon
                                      );
                                    }}
                                    href="#"
                                  >
                                    <Image
                                      src={val.country_flag}
                                      alt={val.currency_code}
                                    ></Image>
                                    <span> {val.currency_code}</span>
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                          <li className="d-none">
                            <BiSearch
                              onClick={() => setSearch_show(!search_show)}
                            />
                          </li>
                          <li>
                            <Login />
                          </li>
                          <li
                            onClick={() =>
                              isLogin
                                ? history.push("/account/wishlist")
                                : history.push("/wishlist")
                            }
                          >
                            <BiHeart className="rcs_cart_mobile_icon1" />{" "}
                            {wishlistlength && wishlistlength != "0" ? (
                              <span className="rcs_wish_badge">
                                {wishlistlength}
                              </span>
                            ) : (
                              ""
                            )}{" "}
                          </li>
                          <li
                            onClick={() => {
                              history.push("/cart");
                            }}
                          >
                            <MdOutlineShoppingBag className="rcs_cart_mobile_icon" />{" "}
                            {cartlength && cartlength != "0" ? (
                              <span className="rcs_cart_badge">
                                {cartlength}
                              </span>
                            ) : (
                              ""
                            )}{" "}
                          </li>
                        </ul>
                      </div>
                    </div>
                    {/* <div className="rcs_menu menu_list_main">
                      <Container className="rcs_custome_container1">
                        <Row>
                          <nav id="rcs_menu_header_top">
                            <ul className="rcs_header_menu">
                              {menudata?.map((value, index) => (
                                <li
                                  className="rcs_top_header_menu"
                                  onMouseEnter={() => {
                                    setHover(1);
                                    setSubmenu(value);
                                  }}
                                  onMouseLeave={() => setHover(0)}
                                >
                                  <h6 className='1st'>

                                    <NavLink to={value?.slug}>
                                      {value?.name}
                                    </NavLink>
                                  </h6>
                                  {value?.design == 5 ? <ul
                                    className="rcs_top_header_menu_dropdown"
                                    onMouseEnter={() => setHover(1)}
                                    onMouseLeave={() => setHover(0)}
                                  >
                                    {submenu?.submenu?.map((val) => (
                                      <li className="dir rcs_top_header_menu1">
                                        <span>
                                          <NavLink to={val?.slug}>
                                            {val.name}
                                          </NavLink>
                                        </span>
                                      </li>
                                    ))}
                                  </ul> : ""}
                                </li>
                              ))}
                            </ul>
                          </nav>
                        </Row>
                      </Container>
                    </div> */}

                    <div className="mg_menu_right_side_area">
                      <div className="rcs_right_side rcs_right_side_mobile d-none d-lg-block">
                        <ul>
                          <li
                            className="d-none d-lg-block"
                            onClickAway={() => setSearch_show(false)}
                          >
                            <img
                              onClick={() => setSearch_show(!search_show)}
                              onMouseOver={() => setSearchhover(true)}
                              onMouseLeave={() => setSearchhover(false)}
                              className="rcs_wishlist_icon"
                              src={searchhover ? Magnify1 : Magnify0}
                            />
                            <Modal
                              className={
                                isScrollValueMoreThanHeaderHeight
                                  ? "rcs_search_modal12"
                                  : "rcs_search_modal"
                              }
                              show={search_show}
                              onHide={() => setSearch_show(false)}
                            >
                              <div className="rcs_search_bar">
                                <Form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    history.push("/search/" + search_text);
                                    window.scrollTo(0, 0);
                                    setSearch_text("");
                                    setSearch_show(false);
                                  }}
                                >
                                  <SearchIcon
                                    onClick={(e) => {
                                      history.push("/search/" + search_text);
                                      setSearch_text("");
                                      setSearch_show(false);
                                    }}
                                  />
                                  <Form.Control
                                    type="text"
                                    placeholder="Search for.."
                                    value={search_text}
                                    onChange={(e) =>
                                      setSearch_text(e.target.value)
                                    }
                                  />
                                  <AiOutlineClose
                                    onClick={() => setSearch_show(!search_show)}
                                  />
                                </Form>
                              </div>
                            </Modal>
                          </li>
                          <li className="d-none d-lg-block">
                            <Login />
                          </li>
                          <li
                            className="d-none d-lg-block"
                            onClick={() =>
                              isLogin
                                ? history.push("/account/wishlist")
                                : history.push("/wishlist")
                            }
                          >
                            <img
                              onMouseOver={() => setWishover(true)}
                              onMouseLeave={() => setWishover(false)}
                              className="rcs_wishlist_icon"
                              src={wishover ? Heart2 : Heart1}
                            />
                            {wishlistlength && wishlistlength != "0" ? (
                              <span className="rcs_wish_badge">
                                {wishlistlength}
                              </span>
                            ) : (
                              ""
                            )}
                          </li>
                          <li
                            className="d-none d-lg-block"
                            onClick={() => {
                              history.push("/cart");
                            }}
                          >
                            <img
                              onMouseOver={() => setCarthover(true)}
                              onMouseLeave={() => setCarthover(false)}
                              className="rcs_wishlist_icon"
                              src={carthover ? Cart2 : Cart1}
                            />
                            {cartlength && cartlength != "0" ? (
                              <span className="rcs_cart_badge">
                                {cartlength}
                              </span>
                            ) : (
                              ""
                            )}
                          </li>
                          <li className="d-none">
                            <Dropdown className="d-inline rcs_dropdown_lang">
                              <Dropdown.Toggle id="dropdown-autoclose-true">
                                <Image
                                  src={dropdown?.img}
                                  style={{ width: "25px" }}
                                ></Image>
                                <span>{dropdown?.code}</span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {currencydata.map((val) => (
                                  <Dropdown.Item
                                    onClick={() => {
                                      currencyHandle(
                                        val.currency_code,
                                        val.country_flag,
                                        val.currency_icon
                                      );
                                    }}
                                    href="#"
                                  >
                                    <Image
                                      src={val.country_flag}
                                      alt={val.currency_code}
                                    ></Image>
                                    <span> {val.currency_code}</span>
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/*mega menu */}
                  {/* <div className="menu_item_main">
                    {submenu?.design != 5 ?
                      <div
                        onMouseEnter={() => setHover(1)}
                        onMouseLeave={() => setHover(0)}
                        className={
                          hover == "1" && submenu?.submenu?.length
                            ? "rcs_top_header_menu_dropdown mega_menu_content_main visible bw-" +
                            submenu?.name?.replaceAll(" ", "-")
                            : "rcs_top_header_menu_dropdown mega_menu_content_main invisible"
                        }
                      >
                        <div className="rcs_megaMenu d-block">
                          <div className="rcs_megaMenu_inner_top">
                            <div className="rcs_megaMenu_inner">
                              <Container fluid className="p-0">
                                <Row className="w-100 m-auto  justify-content-center">
                                  {submenu?.submenu?.map((val, index) => (
                                    <>
                                      <Col
                                        lg={val?.col}
                                        xl={val?.col}
                                        className="pl-0"
                                      >
                                        {val?.submenu?.map((val1) =>
                                          val1?.design == "1" ? (
                                            <div
                                              className={
                                                "rcs_diamonds_content mj_daimond_d_font bw-" +
                                                val1?.name?.replaceAll(" ", "-")
                                              }
                                            >
                                              <div className="mega-list-cat">
                                                <h2 className="title14 font-bold text-uppercase mtb">
                                                  {val1?.name}
                                                </h2>
                                                <ul>
                                                  {val1?.submenu?.map(
                                                    (val2) => (
                                                      <li>
                                                        {val2?.design == 4 ? (
                                                          <Link
                                                            to="#"
                                                            onClick={() =>
                                                              window.open(
                                                                img_url +
                                                                val2?.slug,
                                                                "_target"
                                                              )
                                                            }
                                                          >
                                                            {val2?.img_url
                                                              ?.length ? (
                                                              <img
                                                                src={
                                                                  val2?.img_url
                                                                }
                                                                className="img-size"
                                                                alt={
                                                                  val2?.alt_tag
                                                                }
                                                              />
                                                            ) : (
                                                              ""
                                                            )}
                                                            {val2?.name}
                                                          </Link>
                                                        ) : (
                                                          <Link to={val2?.slug}>
                                                            {val2?.img_url
                                                              ?.length ? (
                                                              <img
                                                                src={
                                                                  val2?.img_url
                                                                }
                                                                className="img-size"
                                                                alt={
                                                                  val2?.alt_tag
                                                                }
                                                              />
                                                            ) : (
                                                              ""
                                                            )}
                                                            {val2?.name}
                                                          </Link>
                                                        )}
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              </div>
                                            </div>
                                          ) : val1?.design == "2" ? (
                                            <div
                                              className={
                                                "rcs_diamonds_content dfL-border_rel bw-" +
                                                val1?.name?.replaceAll(" ", "-")
                                              }
                                            >
                                              <h2> {val1?.name} </h2>
                                              <div className="m-auto w-100 row">
                                                {val1?.submenu?.map((val2) => (
                                                  <div className="cust_padd_diamonds_shape col-lg-6">
                                                    <div className="rcs_diamonds_content_inner">
                                                      <Link to={val2?.slug}>
                                                        {val2?.img_url
                                                          ?.length ? (
                                                          <img
                                                            src={val2?.img_url}
                                                            alt={val2?.alt_tag}
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                        <p>{val2?.name}</p>
                                                      </Link>
                                                    </div>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          ) : val1?.design == "3" ? (
                                            <div className="rcs_diamonds_content">
                                              <Link to={val1?.slug}>
                                                {val1?.img_url?.length ? (
                                                  <img
                                                    src={val1?.img_url}
                                                    alt={val1?.alt_tag}
                                                    className="img-fluid dfl_ddiamond_img"
                                                    style={{
                                                      height: "100%",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </Link>
                                            </div>
                                          ) : (
                                            ""
                                          )
                                        )}
                                        {index < 2 ? (
                                          <div className="dfl_menu_border">
                                            {" "}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </>
                                  ))}
                                </Row>
                              </Container>
                            </div>
                          </div>
                        </div>
                      </div> : ""}

                  </div> */}

                  {/* menu Section */}
                </Col>
              </Row>
            </Container>
          </div>
          <div className="rcs_header">
            <Container fluid className="p-0">
              <Row className="w-100 m-auto">
                <Col sm={12} md={12} lg={12} xs={12} className="rcs_large_Tab1">
                  {/* Menu Section */}
                  <div className="mg_main_d">
                    {/* <div className="mg_menu_logo_main_div d-none">
                      <Nav className="m-auto rcs_mobile_header_menu">
                        <Image
                          onClick={() => toggleDrawer(true)}
                          src={menuImages}
                          alt="menu icon"
                          width="30px"
                        ></Image>
                      </Nav>
                      <div className="rcs_logo_section">
                        <Image
                          onClick={() => history.push("/")}
                          src={logoImage}
                          alt={client_name.c_name}
                        ></Image>
                      </div>
                      <div className="rcs_right_side rcs_right_side_mobile1 d-lg-none">
                        <ul>
                          <li className="rcs_currency">
                            <Dropdown className="d-inline rcs_dropdown_lang">
                              <Dropdown.Toggle id="dropdown-autoclose-true">
                                <Image
                                  src={dropdown?.img}
                                  alt={dropdown?.code}
                                  style={{ width: "25px" }}
                                ></Image>{" "}
                                <span>{dropdown?.code}</span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {currencydata.map((val) => (
                                  <Dropdown.Item
                                    onClick={() => {
                                      currencyHandle(
                                        val.currency_code,
                                        val.country_flag,
                                        val.currency_icon
                                      );
                                    }}
                                    href="#"
                                  >
                                    <Image
                                      src={val.country_flag}
                                      alt={val.currency_code}
                                    ></Image>
                                    <span> {val.currency_code}</span>
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                          <li>
                            <BiSearch
                              onClick={() => setSearch_show(!search_show)}
                            />
                          </li>
                          <li>
                            <Login />
                          </li>
                          <li
                            onClick={() =>
                              isLogin
                                ? history.push("/account/wishlist")
                                : history.push("/wishlist")
                            }
                          >
                            <BiHeart className="rcs_cart_mobile_icon1" />{" "}
                            {wishlistlength && wishlistlength != "0" ? (
                              <span className="rcs_wish_badge">
                                {wishlistlength}
                              </span>
                            ) : (
                              ""
                            )}{" "}
                          </li>
                          <li
                            onClick={() => {
                              history.push("/cart");
                            }}
                          >
                            <MdOutlineShoppingBag className="rcs_cart_mobile_icon" />{" "}
                            {cartlength && cartlength != "0" ? (
                              <span className="rcs_cart_badge">
                                {cartlength}
                              </span>
                            ) : (
                              ""
                            )}{" "}
                          </li>
                        </ul>
                      </div>
                    </div> */}
                    <div className="rcs_menu menu_list_main">
                      <Container className="rcs_custome_container1">
                        <Row>
                          <nav id="rcs_menu_header_top">
                            <ul className="rcs_header_menu">
                              {menudata?.map((value, index) => (
                                <li
                                  className="rcs_top_header_menu"
                                  onMouseEnter={() => {
                                    setHover(1);
                                    setSubmenu(value);
                                  }}
                                  onMouseLeave={() => setHover(0)}
                                >
                                  <h6 className='1st'>

                                    <NavLink to={value?.slug}>
                                      {value?.name}
                                    </NavLink>
                                  </h6>
                                  {value?.design == 5 ? <ul
                                    className="rcs_top_header_menu_dropdown"
                                    onMouseEnter={() => setHover(1)}
                                    onMouseLeave={() => setHover(0)}
                                  >
                                    {submenu?.submenu?.map((val) => (
                                      <li className="dir rcs_top_header_menu1">
                                        <span>
                                          <NavLink to={val?.slug}>
                                            {val.name}
                                          </NavLink>
                                        </span>
                                      </li>
                                    ))}
                                  </ul> : ""}
                                </li>
                              ))}
                            </ul>
                          </nav>
                        </Row>
                      </Container>
                    </div>

                    {/* <div className="mg_menu_right_side_area">
                      <div className="rcs_right_side rcs_right_side_mobile d-none d-lg-block">
                        <ul>
                          <li
                            className="d-none d-lg-block"
                            onClickAway={() => setSearch_show(false)}
                          >
                            <img
                              onClick={() => setSearch_show(!search_show)}
                              onMouseOver={() => setSearchhover(true)}
                              onMouseLeave={() => setSearchhover(false)}
                              className="rcs_wishlist_icon"
                              src={searchhover ? Magnify1 : Magnify0}
                            />
                            <Modal
                              className={
                                isScrollValueMoreThanHeaderHeight
                                  ? "rcs_search_modal12"
                                  : "rcs_search_modal"
                              }
                              show={search_show}
                              onHide={() => setSearch_show(false)}
                            >
                              <div className="rcs_search_bar">
                                <Form
                                  onSubmit={(e) => {
                                    e.preventDefault();
                                    history.push("/search/" + search_text);
                                    window.scrollTo(0, 0);
                                    setSearch_text("");
                                    setSearch_show(false);
                                  }}
                                >
                                  <SearchIcon
                                    onClick={(e) => {
                                      history.push("/search/" + search_text);
                                      setSearch_text("");
                                      setSearch_show(false);
                                    }}
                                  />
                                  <Form.Control
                                    type="text"
                                    placeholder="Search for.."
                                    value={search_text}
                                    onChange={(e) =>
                                      setSearch_text(e.target.value)
                                    }
                                  />
                                  <AiOutlineClose
                                    onClick={() => setSearch_show(!search_show)}
                                  />
                                </Form>
                              </div>
                            </Modal>
                          </li>
                          <li className="d-none d-lg-block">
                            <Login />
                          </li>
                          <li
                            className="d-none d-lg-block"
                            onClick={() =>
                              isLogin
                                ? history.push("/account/wishlist")
                                : history.push("/wishlist")
                            }
                          >
                            <img
                              onMouseOver={() => setWishover(true)}
                              onMouseLeave={() => setWishover(false)}
                              className="rcs_wishlist_icon"
                              src={wishover ? Heart2 : Heart1}
                            />
                            {wishlistlength && wishlistlength != "0" ? (
                              <span className="rcs_wish_badge">
                                {wishlistlength}
                              </span>
                            ) : (
                              ""
                            )}
                          </li>
                          <li
                            className="d-none d-lg-block"
                            onClick={() => {
                              history.push("/cart");
                            }}
                          >
                            <img
                              onMouseOver={() => setCarthover(true)}
                              onMouseLeave={() => setCarthover(false)}
                              className="rcs_wishlist_icon"
                              src={carthover ? Cart2 : Cart1}
                            />
                            {cartlength && cartlength != "0" ? (
                              <span className="rcs_cart_badge">
                                {cartlength}
                              </span>
                            ) : (
                              ""
                            )}
                          </li>
                          <li className="d-none">
                            <Dropdown className="d-inline rcs_dropdown_lang">
                              <Dropdown.Toggle id="dropdown-autoclose-true">
                                <Image
                                  src={dropdown?.img}
                                  style={{ width: "25px" }}
                                ></Image>
                                <span>{dropdown?.code}</span>
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {currencydata.map((val) => (
                                  <Dropdown.Item
                                    onClick={() => {
                                      currencyHandle(
                                        val.currency_code,
                                        val.country_flag,
                                        val.currency_icon
                                      );
                                    }}
                                    href="#"
                                  >
                                    <Image
                                      src={val.country_flag}
                                      alt={val.currency_code}
                                    ></Image>
                                    <span> {val.currency_code}</span>
                                  </Dropdown.Item>
                                ))}
                              </Dropdown.Menu>
                            </Dropdown>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </div>

                  {/*mega menu */}
                  <div className="menu_item_main">
                    {submenu?.design != 5 ?
                      <div
                        onMouseEnter={() => setHover(1)}
                        onMouseLeave={() => setHover(0)}
                        className={
                          hover == "1" && submenu?.submenu?.length
                            ? "rcs_top_header_menu_dropdown mega_menu_content_main visible bw-" +
                            submenu?.name?.replaceAll(" ", "-")
                            : "rcs_top_header_menu_dropdown mega_menu_content_main invisible"
                        }
                      >
                        <div className="rcs_megaMenu d-block">
                          <div className="rcs_megaMenu_inner_top">
                            <div className="rcs_megaMenu_inner">
                              <Container fluid className="p-0">
                                <Row className="w-100 m-auto  justify-content-center">
                                  {submenu?.submenu?.map((val, index) => (
                                    <>
                                      <Col
                                        lg={val?.col}
                                        xl={val?.col}
                                        className="pl-0"
                                      >
                                        {val?.submenu?.map((val1) =>
                                          val1?.design == "1" ? (
                                            <div
                                              className={
                                                "rcs_diamonds_content mj_daimond_d_font bw-" +
                                                val1?.name?.replaceAll(" ", "-")
                                              }
                                            >
                                              <div className="mega-list-cat">
                                                <h2 className="title14 font-bold text-uppercase mtb">
                                                  {val1?.name}
                                                </h2>
                                                <ul>
                                                  {val1?.submenu?.map(
                                                    (val2) => (
                                                      <li>
                                                        {val2?.design == 4 ? (
                                                          <Link
                                                            to="#"
                                                            onClick={() =>
                                                              window.open(
                                                                img_url +
                                                                val2?.slug,
                                                                "_target"
                                                              )
                                                            }
                                                          >
                                                            {val2?.img_url
                                                              ?.length ? (
                                                              <img
                                                                src={
                                                                  val2?.img_url
                                                                }
                                                                className="img-size"
                                                                alt={
                                                                  val2?.alt_tag
                                                                }
                                                              />
                                                            ) : (
                                                              ""
                                                            )}
                                                            {val2?.name}
                                                          </Link>
                                                        ) : (
                                                          <Link to={val2?.slug}>
                                                            {val2?.img_url
                                                              ?.length ? (
                                                              <img
                                                                src={
                                                                  val2?.img_url
                                                                }
                                                                className="img-size"
                                                                alt={
                                                                  val2?.alt_tag
                                                                }
                                                              />
                                                            ) : (
                                                              ""
                                                            )}
                                                            {val2?.name}
                                                          </Link>
                                                        )}
                                                      </li>
                                                    )
                                                  )}
                                                </ul>
                                              </div>
                                            </div>
                                          ) : val1?.design == "2" ? (
                                            <div
                                              className={
                                                "rcs_diamonds_content dfL-border_rel bw-" +
                                                val1?.name?.replaceAll(" ", "-")
                                              }
                                            >
                                              <h2> {val1?.name} </h2>
                                              <div className="m-auto w-100 row">
                                                {val1?.submenu?.map((val2) => (
                                                  <div className="cust_padd_diamonds_shape col-lg-6">
                                                    <div className="rcs_diamonds_content_inner">
                                                      <Link to={val2?.slug}>
                                                        {val2?.img_url
                                                          ?.length ? (
                                                          <img
                                                            src={val2?.img_url}
                                                            alt={val2?.alt_tag}
                                                          />
                                                        ) : (
                                                          ""
                                                        )}
                                                        <p>{val2?.name}</p>
                                                      </Link>
                                                    </div>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          ) : val1?.design == "3" ? (
                                            <div className="rcs_diamonds_content">
                                              <Link to={val1?.slug}>
                                                {val1?.img_url?.length ? (
                                                  <img
                                                    src={val1?.img_url}
                                                    alt={val1?.alt_tag}
                                                    className="img-fluid dfl_ddiamond_img"
                                                    style={{
                                                      height: "100%",
                                                      objectFit: "cover",
                                                    }}
                                                  />
                                                ) : (
                                                  ""
                                                )}
                                              </Link>
                                            </div>
                                          ) : (
                                            ""
                                          )
                                        )}
                                        {index < 2 ? (
                                          <div className="dfl_menu_border">
                                            {" "}
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </Col>
                                    </>
                                  ))}
                                </Row>
                              </Container>
                            </div>
                          </div>
                        </div>
                      </div> : ""}

                  </div>

                  {/* menu Section */}
                </Col>
              </Row>
            </Container>
          </div>
          {/* Header Section */}

          {hover == "1" && submenu?.submenu?.length ? (
            <div className="hover_overlayer"></div>
          ) : (
            ""
          )}
        </div>
        {/* Mobile Menu Section */}
        <div className="rcs_menu_mobile d-none" onKeyDown={(e) => {
          if (e.key == 'Enter') {
            toggleDrawer(false);
            history.push("/search/" + search_text);
            setSearch_text("");
          }
        }}>
          <Container>
            <Row>
              <Drawer
                anchor="left"
                open={left}
                onClose={() => toggleDrawer(false)}
              >
                <div role="presentation" >
                  <div
                    className={
                      show === true ? "mobile-menu" : "mobile-menu nav-open"
                    }
                  >
                    <div className="ddov_search_in_mobile ml-2 mr-2  mt-4 ">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          history.push("/search/" + search_text);
                          window.scrollTo(0, 0);
                          setSearch_text("");
                        }}
                      >
                        <TextField
                          id="search"
                          type="search"
                          label="Search"
                          value={search_text}
                          onChange={(e) =>
                            setSearch_text(e.target.value)
                          }
                          sx={{ width: '98%' }}
                          size="small"
                          className="ddov_mobile_view_search"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <SearchIcon
                                  onClick={(e) => {
                                    history.push("/search/" + search_text);
                                    setSearch_text("");
                                    toggleDrawer(false)
                                  }} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Form>

                    </div>
                    <div className="inner-mobile-menu" >
                      <Accordion defaultActiveKey="0">
                        {/* <Accordion.Item eventKey="0">
                                <Accordion.Header><Link to="#"><span className='auth'><img src={User} alt="" /></span> LOGIN/SIGNUP</Link></Accordion.Header>
                            </Accordion.Item> */}
                        {menudata?.map((val, index) => (
                          <Accordion.Item eventKey={index}>
                            <Accordion.Header>
                              <Link
                                to={val.slug}
                                onClick={() => toggleDrawer(false)}
                              >
                                {val.name}
                              </Link>
                            </Accordion.Header>
                            <Accordion.Body>
                              {val?.submenu?.map((val1) =>
                                val1?.submenu?.map((val2) =>
                                  val2?.design == 3 ? (
                                    <img
                                      className="w-100 mt-2"
                                      onClick={() => history.push(val2?.slug)}
                                      src={val2?.img_url}
                                      alt={val2?.alt_tag}
                                    />
                                  ) : (
                                    <>
                                      <h2 className="title">{val2?.name}</h2>
                                      <ul
                                        className={
                                          val2?.design == 2
                                            ? "two-col-list"
                                            : "single-col-list"
                                        }
                                      >
                                        {val2?.submenu?.map((val3) => (
                                          <li>
                                            <Link
                                              onClick={() => toggleDrawer(false)}
                                              to={val3.slug}
                                            >
                                              {val3?.img_url ? (
                                                <img
                                                  src={val3?.img_url}
                                                  alt={val3?.alt_tag}
                                                />
                                              ) : (
                                                ""
                                              )}{" "}
                                              {val3?.name}
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </>
                                  )
                                )
                              )}
                            </Accordion.Body>
                          </Accordion.Item>
                        ))}
                        <Accordion.Item eventKey="7" className="contactDetail">
                          <Accordion.Header>Contact us</Accordion.Header>
                          <div className="contact_detail">
                            <ul>
                              {/* <li>
                                <a
                                  onClick={() => setShow(false)}
                                  target="_blank"
                                  href="https://api.whatsapp.com/send?phone=+6469929024 &amp;text=Hi Arman Jwelers ! I have a few queries. Could you please help me out?"
                                >
                                  <span>
                                    <BsFillChatFill />
                                  </span>{" "}
                                  Chat
                                </a>
                              </li> */}
                              <li>
                                <a
                                  onClick={() => { setShow(false); window.location.href = `${contact_number_link}` }}
                                  href="#"
                                  onKeyDown={() => toggleDrawer(false)}
                                >
                                  <span>
                                    <BsFillTelephoneFill />
                                  </span>{" "}
                                  Call
                                </a>
                              </li>
                              <li>
                                <a
                                  onClick={() => { setShow(false); window.location.href = `${Email_details_link}`; }}
                                  href="#"
                                >
                                  <span>
                                    <IoMdMail />
                                  </span>{" "}
                                  Email
                                </a>
                              </li>
                              <li >
                                <Link
                                  onClick={() => { setShow(false); toggleDrawer(false) }}
                                  to="/contact"
                                >
                                  <span>
                                    <IoMdContact />
                                  </span>{" "}
                                  Contact
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
              </Drawer>
            </Row>
          </Container>
        </div>
        {/* Mobile Menu Section */}
      </div >
    </>
  );
};
export default MegaMenu;
