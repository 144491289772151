import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import "../../Assets/css/home.css";

const Insta = () => {
    return (
        <>
            <div className='ddov_insta_section'>
                <Container>
                    <Row>
                        <div className="rcs_news_content1 w-100  mb-3" >
                            <h2 className='text-center'>Our Social Media</h2>
                            <p className='text-center'>Follow us on Instagram <a href='#' className='text-dark' style={{
                                color: "#0D0900", fontWeight: "500"
                            }}>@diamonddoveslondon</a></p>
                        </div>
                    </Row>
                </Container>
                <iframe src="https://instagram.demobw.live/diamonddoves/" frameborder="0" width="100%" class="insta-iframe"></iframe>
                {/* <div className="elfsight-app-368122dd-eb07-4a5c-b0a6-014ed3804162"></div> */}
            </div>

        </>
    )
}

export default Insta;